<template>
  <div class="d-flex">
    <v-card
      :style="`width: ${$vuetify.breakpoint.width < 600 ? '55%' : '35%'}`"
      elevation="0"
      class="mx-auto pb-10"
    >
      <div class="" style="text-align: center">
        <h1
          :class="`${
            $vuetify.breakpoint.width < 694 ? 'headline' : 'display-1'
          } font-weight-bold pb-10`"
        >
          {{ $t("signUpPage.title") }}
        </h1>
        <v-window v-model="tabs">
          <v-window-item :value="1">
            <v-form ref="signupBasicForm" class="mt-4">
              <v-row>
                <v-col sm="6" cols="6">
                  <v-text-field
                    :label="$t('signUpPage.firstName')"
                    fill
                    outlined
                    v-model="new_user.firstName"
                    style="width: 150%"
                    dense
                    :rules="requiredRules"
                  ></v-text-field>
                </v-col>
                <v-col sm="6" cols="6">
                  <v-text-field
                    :label="$t('signUpPage.lastName')"
                    fill
                    outlined
                    v-model="new_user.lastName"
                    style="width: 150%"
                    dense
                    :rules="requiredRules"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-text-field
                :label="$t('signUpPage.phoneNumber')"
                hint="09XXXXXXXX"
                fill
                outlined
                class="pt-4"
                type="number"
                v-model="new_user.phoneNumber"
                :rules="[phoneRules.requred, phoneRules.format1, phoneRules.format2]"
                style="width: 150%"
                dense
                validate-on-blur
              ></v-text-field>
              <v-text-field
                :label="$t('signUpPage.workPlace')"
                :rules="workPlaceRules"
                fill
                outlined
                class="pt-4"
                v-model="new_user.workPlace"
                style="width: 150%"
                dense
              ></v-text-field>
              <v-text-field
                :label="$t('signUpPage.birthdate')"
                fill
                outlined
                class="pt-4"
                type="date"
                :max="maxDate"
                min="1900-01-01"
                v-model="new_user.birthDate"
                :rules="requiredRules"
                style="width: 150%"
                dense
              ></v-text-field>

              <v-text-field
                :label="$t('signUpPage.email')"
                fill
                outlined
                class="pt-4"
                v-model="new_user.email"
                style="width: 150%"
                dense
                :rules="emailRules"
              ></v-text-field>
              <v-text-field
                :label="$t('signUpPage.password')"
                type="password"
                :rules="passRules"
                fill
                outlined
                class="pt-4"
                v-model="new_user.password"
                style="width: 150%"
                dense
              ></v-text-field>
            </v-form>
          </v-window-item>

          <v-window-item :value="2">
            <v-row class="pa-0">
              <v-btn text depressed plain :ripple="false" @click="back">
                <v-icon>{{ $t("signUpPage.backIcon") }}</v-icon>
                {{ $t("signUpPage.back") }}</v-btn
              >
            </v-row>
            <v-form ref="signupExtraForm">
              <v-container>
                <v-row>
                  <v-radio-group v-model="new_user.gender" :rules="requiredRules">
                    <template v-slot:label>
                      <div class="font-weight-bold pt-4" style="font-size: 1rem">
                        {{ $t("signUpPage.gender") }}
                      </div>
                    </template>
                    <v-checkbox
                      value="male"
                      :label="$t('signUpPage.male')"
                      requred
                      v-model="new_user.gender"
                    ></v-checkbox>
                    <v-checkbox
                      value="female"
                      :label="$t('signUpPage.female')"
                      requred
                      v-model="new_user.gender"
                    ></v-checkbox>
                  </v-radio-group>
                </v-row>
              </v-container>

              <v-container style="margin-top: -50px">
                <v-row>
                  <v-col cols="12" style="text-align: start"> </v-col>
                  <v-radio-group v-model="professions" :rules="requiredRules">
                    <template v-slot:label>
                      <div class="font-weight-bold pt-4" style="font-size: 1rem">
                        {{ $t("signUpPage.occupation") }}
                      </div>
                    </template>
                    <v-checkbox
                      v-for="(p, i) in professionsData"
                      :key="i"
                      :value="p.id"
                      v-model="professions"
                      :label="$i18n.locale == 'ar' ? p.arName : p.name"
                      requred
                      :rules="rules"
                    ></v-checkbox>
                  </v-radio-group>
                </v-row>
              </v-container>
            </v-form>
          </v-window-item>
        </v-window>

        <v-btn
          v-if="tabs == 2"
          color="black"
          @click="submitHandler"
          width="100%"
          style="
            color: white;
            font-size: 1.1rem;
            border-width: 1.6px;
            letter-spacing: 0px;
          "
          class="text-capitalize"
          :loading="isLoading"
        >
          <span> {{ $t("signUpPage.submit") }} </span>
        </v-btn>
        <v-btn
          v-else
          color="black"
          @click="proceedHandler"
          width="100%"
          style="font-size: 1.1rem; border-width: 1.6px; letter-spacing: 0px"
          class="text-capitalize"
          :outlined="tabs == 1"
          :loading="isLoading"
        >
          <span>{{ $t("signUpPage.proceed") }}</span>
        </v-btn>
        <v-alert class="mt-3" width="100%" v-if="showErrorAlert" type="error">
          {{ errorMsg }}
        </v-alert>
      </div>
      <v-btn
        v-show="$vuetify.breakpoint.width <= 1263"
        color="black"
        @click="$router.push('/login')"
        width="100%"
        style="color: white; letter-spacing: 0px"
        class="mt-6 text-capitalize"
        text
      >
        {{ $t("signUpPage.loginMobile") }}
      </v-btn>
    </v-card>
    <completion-dialog :dialog="showCompletionDialog" @close="$router.push('/items')" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import CompletionDialog from "./components/CompletionDialog.vue";

export default {
  name: "SignUp",
  components: { CompletionDialog },
  data() {
    return {
      new_user: {},
      emailRules: [
        (v) => !!v || this.$t("signUpPage.required"),
        (v) => /.+@.+\..+/.test(v) || this.$t("signUpPage.emailValid"),
      ],
      phoneRules: {
        required: (v) => !!v || this.$t("signUpPage.required"),
        format1: (v) =>
          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) ||
          this.$t("signUpPage.phoneValid"),
        format2: (v) =>
          v[0].toString() + v[1].toString() == "09" || this.$t("signUpPage.phoneValid"),
      },
      passRules: [(v) => v.length >= 4 || this.$t("signUpPage.passwordValid")],
      workPlaceRules: [(v) => v.length <= 100 || this.$t("signUpPage.workPlaceValid")],
      requiredRules: [(v) => !!v || this.$t("signUpPage.required")],
      isLoading: false,
      showErrorAlert: false,
      showCompletionDialog: false,
      tabs: 1,
      actionButtonText: "",
      professions: [],
      errorMsg: "",
      maxDate: new Date().toISOString().slice(0, 10),
    };
  },
  methods: {
    getProfessions() {
      this.$store
        .dispatch("authModule/getProfessions")
        .then(() => {})
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t("app.errorNotif"),
            type: "is-danger",
          });
        })
        .finally(() => {});
    },

    submitHandler() {
      var isValid = this.$refs.signupExtraForm.validate();
      if (this.tabs == 2) {
        if (isValid) this.signUp();
      }
    },
    proceedHandler() {
      var isValid = this.$refs.signupBasicForm.validate();
      if (isValid) {
        this.tabs = 2;
      }
    },
    signUp() {
      this.showErrorAlert = false;

      this.new_user.roleId = 2;
      this.new_user.professionIds = this.professions;
      this.new_user.email?.toLowerCase()
      this.isLoading = true;
      this.$store
        .dispatch("authModule/signUp", {
          body: this.new_user,
        })
        .then(() => {
          this.showCompletionDialog = true;
        })
        .catch((err) => {
          if (err.response.data.data.messages.errors[0].rule == "unique") {
            this.errorMsg = this.$t("signUpPage.errorMsg");
            this.showErrorAlert = true;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    back() {
      this.tabs = 1;
      this.actionButtonText = this.$t("signUpPage.proceed");
    },
  },
  created() {
    this.getProfessions();
  },
  computed: {
    ...mapState({
      professionsData: (state) => state.authModule.professions,
    }),
    rules() {
      return [this.professions.length > 0 || this.$t("signUpPage.professionRules")];
    },
  },
};
</script>
