<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 black " style="color: white;">
          {{ $t("completion.title") }}
        </v-card-title>

        <v-card-text class="pt-4" style="font-size:1.3rem"> {{ $t("completion.body") }} </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="$emit('close')"
            style="font-size: 1.1rem; letter-spacing: 0px"
          >
            {{ $t("completion.okay") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
export default {
  props: ["dialog"],
};
</script>